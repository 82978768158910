<template>
    <v-container fluid grid-list-md>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        <v-layout justify-center wrap>
            <v-flex
                v-for="(card, index) in cards"
                :key="index"
                xs6 sm6 md6 lg4
            >
                <v-card 
                    flat 
                    justify-center 
                    :color="card.color" 
                    @click="selectFunc(index)" 
                    class="card-title" 
                    :style="{ 'box-shadow': card.shadowColor }" 
                >
                    <v-card-title class="headline justify-center">
                        <div class="text-xs-center">
                            <v-icon size="55px" dark>{{card.icon}}</v-icon>
                            <div>{{ $t(card.title) }}</div>
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout mt-3>
            <v-flex xs12>
                <v-carousel 
                    height="100%"
                    hide-delimiters 
                    light
                    :cycle="cycle"
                >
                    <v-carousel-item
                        v-for="(instructionPic, index) in instructionPics"
                        :src="instructionPic.src"
                        :key="index"
                        style="height: auto"
                    >
                        <div class="carousel-title">
                            {{ $t(instructionPic.title) }}
                        </div>   
                    </v-carousel-item>
                </v-carousel>
            </v-flex>
        </v-layout>
        <!-- <v-layout mt-3>
            <v-flex>
                <Restaurants></Restaurants>
            </v-flex>
        </v-layout> -->
    </v-container>
</template>

<script>
import store from '@/store'
import UserSyncedWatcher from "@/mixins/UserSyncedWatcher"
import { parseBoxQR } from "@/utils/AuthService"
import { HOMETYPES } from '@/utils/utils'
import { SideBtnType, DoorCode, UrlRedirectCode } from '@/store'
import i18n from '@/i18n'
// import Restaurants from '../components/Restaurants'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import GoogleLoginControl from '@/mixins/GoogleLoginControl.vue'

export default {
    name: 'StoreHome',
    components: { WaitingDialog },
    mixins: [ UserSyncedWatcher, WaitDialogControl, GoogleLoginControl ],
    props: {
        query: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            cycle: false,
            cards: [
                { title: 'home.store-buy', color: 'purple', shadowColor: '0 5px 0 0 #4A148C', icon: 'add_shopping_cart', ratio: 0.7, to: 'Buy' },
            ],
            originQuery: '',
            parsedQuery: null,
            instructionPics: [
                { title: 'home.store-buy-process', src: require('../assets/client-storebuy_' + i18n.locale + '.svg') }
            ]
        }
    },
    methods: {
        parseQuery() {
            if (this.query && this.query !== this.originQuery) {
                this.parsedQuery = parseBoxQR(this.query, false)
                this.originQuery = this.query
                if (store.getters.user.synced) this.shortcut()
                else this.dialog = true
            }
        },
        shortcut() {
            if (store.getters.user.isStorehost) {
                this.$router.push({name: 'storehostPutin', params: {box: this.parsedQuery.box_id, door: this.parsedQuery.door_id}})
            } else {
                this.$router.push({name: 'Buy', params: {box: this.parsedQuery.box_id, door: this.parsedQuery.door_id}})
            }
        },
        selectFunc: function(index) {
            store.commit('setClientId')
            let card = this.cards[index]
            this.$router.push({ name: card.to })
        },
        pageHome() {
            if(store.getters.pageHome !== HOMETYPES.STORE)
                store.commit('setPageHome', HOMETYPES.STORE)
            if (store.getters.user.id === 0 && (location.href.indexOf(DoorCode) !== -1 || location.href.indexOf(UrlRedirectCode) !== -1 )) {
                this.$router.push(`/${i18n.locale}/storehome`)
            }
        },
        checkCodeLogin() {
            this.pageHome()
            if(this.isCodeLogin()) {
                const self = this;
                this.showDialog("Loading",null,100000000000)
                this.googleLogin().then( ()=>{
                    self.closeDialog();
                })
            }
        }
    },
    activated() {
        // this.cycle = true
        this.pageHome()
        store.commit('setTitle', 'b-box')
        store.commit('setSideBtnType', SideBtnType.Navi)
        this.parseQuery()

        //重新載入頁面一次
        // if (localStorage.getItem('reloaded')) {
        //     localStorage.removeItem('reloaded')
        // } else {
        //     localStorage.setItem('reloaded', '1')
        //     location.reload()
        // }
    },
    deactivated() {
        this.pageHome()
        console.log('deactivated')
        // this.cycle = false
    }, 
    // created() {
    //     console.log('created')
    // },
    // beforeMount() {
    //     console.log('beforeMount')
    // },
    mounted() {
        this.checkCodeLogin();
    }
}
</script>

<style scoped>
    .carousel-title {
        position: absolute;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        font-size:  18px;
        font-weight: bold;
        color: white;
        padding: 5px;
        width: 100%;
        text-align: center;
    }

    .card-title {
        color: white;
        font-weight: bold;
        border-radius: 8px;
    }
</style>